<template>
    <div class="py-8">
        <v-app-bar flat color="transparent">
            <app-bar-nav-icon />
            <v-toolbar-title class="breadcrumbs d-flex align-center">
                <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
                <router-link
                    class="text-decoration--none"
                    :to="{ name: 'users' }"
                    >Users</router-link
                >
            </v-toolbar-title>
        </v-app-bar>
        <v-container fill-height>
            <v-row class="justify-center align-center">
                <v-col cols="12" sm="6">
                    <div class="ma-4" v-if="form">
                        <div
                            class="display-2 font-weight-bold my-6 text-capitalize"
                        >
                            {{ displayName }}
                        </div>
                        <avatar-form
                            :user="user"
                            @change="avatarChange"
                            @avatarRemove="userAvatarRemoved"
                            :avatarLoading="avatarLoading"
                        />

                        <v-form ref="form" v-if="form">
                            <label class="text-field-label">Name</label>
                            <v-text-field
                                flat
                                solo
                                required
                                class="mt-2"
                                v-model="form.full_name"
                                :error-messages="form.$getError('full_name')"
                                :loading="loading"
                            ></v-text-field>

                            <label class="text-field-label">Email</label>
                            <v-text-field
                                flat
                                solo
                                required
                                class="mt-2"
                                v-model="form.email"
                                :error-messages="form.$getError('email')"
                                :loading="loading"
                            >
                                <v-tooltip slot="append" top v-if="user.email">
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            color="success"
                                            v-on="on"
                                            v-if="user.email_verified"
                                            >{{ icons.check }}</v-icon
                                        >
                                        <v-icon
                                            color="warning"
                                            v-on="on"
                                            v-else
                                            >{{ icons.alert }}</v-icon
                                        >
                                    </template>
                                    <span v-if="user.email_verified"
                                        >Email Verified</span
                                    >
                                    <span v-else>Unverified email</span>
                                </v-tooltip>
                            </v-text-field>

                            <label class="text-field-label">Phone number</label>
                            <v-text-field
                                flat
                                solo
                                required
                                class="mt-2"
                                v-model="form.phone_number"
                                :error-messages="form.$getError('phone_number')"
                                :loading="loading"
                            >
                                <v-tooltip
                                    slot="append"
                                    top
                                    v-if="user.phone_number"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            color="success"
                                            v-on="on"
                                            v-if="user.phone_number_verified"
                                            >{{ icons.check }}</v-icon
                                        >
                                        <v-icon
                                            color="warning"
                                            v-on="on"
                                            v-else
                                            >{{ icons.alert }}</v-icon
                                        >
                                    </template>
                                    <span v-if="user.phone_verified"
                                        >Phone Number Verified</span
                                    >
                                    <span v-else>Unverified Phone Number</span>
                                </v-tooltip>
                            </v-text-field>

                            <div class="mt-4">
                                <v-btn
                                    color="primary"
                                    depressed
                                    class="mr-4 px-6"
                                    height="40px"
                                    @click="updateUserDetails"
                                    :loading="form.$busy"
                                    >Update</v-btn
                                >
                                <v-btn
                                    color="red"
                                    dark
                                    depressed
                                    class="mr-4 px-6"
                                    height="40px"
                                    @click="deleteDialog = true"
                                    :loading="form.$busy"
                                    >Delete</v-btn
                                >
                            </div>
                        </v-form>

                        <v-dialog v-model="deleteDialog" max-width="290">
                            <v-card>
                                <v-card-title class="headline"
                                    >Delete ?</v-card-title
                                >

                                <v-card-text
                                    >Are you sure you want to delete this
                                    account ?</v-card-text
                                >

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        @click="deleteDialog = false"
                                        :loading="form.$busy"
                                        >Cancel</v-btn
                                    >
                                    <v-btn
                                        color="red"
                                        text
                                        @click="deleteUser"
                                        :loading="form.$busy"
                                        >Yes, Delete please</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-overlay absolute :value="loading" opacity="0.2">
            <v-progress-circular
                indeterminate
                color="primary"
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
            {{ snackbar.message }}
            <v-btn text @click="snackbar.show = false">OK</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import { mdiChevronLeft, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import AvatarForm from './components/AvatarForm'
import Form from '@/utils/form'

export default {
    name: 'UserDetails',

    components: {
        AvatarForm,
        AppBarNavIcon
    },

    data() {
        return {
            icons: {
                arrowLeft: mdiChevronLeft,
                check: mdiCheck,
                alert: mdiAlertCircleOutline
            },
            loading: false,
            avatarLoading: false,
            deleteDialog: false,
            form: null,
            showCropperModal: false,
            avatarTimestamp: new Date().getTime(),
            snackbar: {
                show: false,
                message: null,
                color: ''
            }
        }
    },

    computed: {
        ...mapState({
            user: state => state.user.userDetails,
            authUser: state => state.auth.user
        }),
        displayName() {
            return this.form.full_name || this.form.email
        }
    },

    methods: {
        ...mapActions({
            getUserDetails: 'user/getUserDetails',
            changeAvatar: 'user/changeAvatar',
            updateUser: 'user/updateUser'
        }),

        ...mapMutations({
            clearUserDetails: 'user/clearUserDetails',
            setSelectedUserAvatar: 'user/setSelectedUserAvatar',
            userAvatarRemoved: 'user/userAvatarRemoved'
        }),

        async getUser() {
            this.loading = true
            await this.getUserDetails(this.$route.params.id)
            this.form = new Form(this.user)
            this.loading = false
        },

        async avatarChange(image) {
            this.avatarLoading = true
            let data = new FormData()
            data.append('user_id', this.user.id)
            data.append('avatar', image)
            await this.changeAvatar(data)
            this.avatarLoading = false
            this.showSnackbar('Avatar successfully updated!', 'success')
        },

        async updateUserDetails() {
            this.form.$busy = true
            this.form.$clearErrors()
            this.updateUser(this.form.$data())
                .then(() => {
                    this.form.$busy = false
                    this.showSnackbar(
                        'User details successfully updated!',
                        'success'
                    )
                })
                .catch(err => {
                    this.form.$busy = false
                    if (err.response.status === 422) {
                        this.form.$setErrors(err.response.data.errors)
                    }
                })
        },

        deleteUser() {
            this.form.$busy = true
            this.user.delete().then(() => {
                this.form.$busy = false
                this.deleteDialog = false
                this.showSnackbar('User deleted successfully!')
                this.$router.replace('/users')
            })
        },

        showSnackbar(message, color) {
            this.snackbar.message = message
            this.snackbar.show = true
            this.snackbar.color = color
        }
    },

    created() {
        this.getUser()
    },

    destroyed() {
        this.clearUserDetails()
    },

    watch: {
        $route() {
            this.getUser()
        }
    }
}
</script>
