<template>
    <div>
        <v-app-bar flat color="transparent" class="main-appbar">
            <app-bar-nav-icon />
            <v-skeleton-loader
                type="chip"
                transition="fade-transition"
                v-if="loading"
            ></v-skeleton-loader>

            <v-toolbar-title v-else class="headline font-weight-bold"
                >Users ({{ users.length }})</v-toolbar-title
            >

            <v-spacer></v-spacer>
            <v-row v-if="showSearch" align="center" style="max-width: 300px;">
                <v-text-field
                    flat
                    hide-details
                    solo
                    rounded
                    clearable
                    background-color="grey lighten-3"
                    label="Search name, email or phone number"
                    v-model="filter.search"
                    @input="search"
                ></v-text-field>
            </v-row>

            <v-skeleton-loader
                type="actions"
                transition="fade-transition"
                v-if="loading"
            ></v-skeleton-loader>

            <template v-else>
                <v-btn class="ml-5" icon @click="showSearch = !showSearch">
                    <img src="@/assets/icons/search.svg" alt height="17.7px" />
                </v-btn>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mr-1" icon @click="exportList" v-on="on">
                            <v-icon>{{ icons.export }}</v-icon>
                        </v-btn>
                    </template>
                    <span>Export to Excel</span>
                </v-tooltip>
            </template>
        </v-app-bar>

        <div
            class="mt-12 mx-4"
            id="user-list"
            v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="loading"
            infinite-scroll-distance="100"
        >
            <v-simple-table>
                <thead>
                    <tr>
                        <th width="25%" colspan="2" class="text-left pa-0">
                            Name
                        </th>
                        <th width="25%" class="text-left">Email</th>
                        <th width="25%" class="text-left">Phone number</th>
                        <th width="25%" class="text-left">Added date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="user in users"
                        :key="user.id"
                        class="clickable"
                        @click="
                            $router.push({
                                name: 'user.details',
                                params: { id: user.id }
                            })
                        "
                    >
                        <td colspan="2" class="py-5">
                            <user-avatar
                                :avatar="user.avatar"
                                size="40"
                                class="mr-4"
                            />
                            {{ user.full_name }}
                        </td>
                        <td class="py-5">{{ user.email }}</td>
                        <td class="py-5">
                            {{
                                user.phone_number ? '+' + user.phone_number : ''
                            }}
                        </td>
                        <td class="py-5">{{ user.addedDate }}</td>
                    </tr>

                    <template v-if="loading">
                        <tr v-for="index in 10" :key="index + '-skeleton'">
                            <td class="py-3 pl-0" width="5%">
                                <v-skeleton-loader
                                    class="avatar-loading--36"
                                    type="avatar"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                        </tr>
                    </template>
                </tbody>
            </v-simple-table>
        </div>

        <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
            {{ snackbar.message }}
            <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
    mdiDotsVertical,
    mdiUnfoldMoreHorizontal,
    mdiMagnify,
    mdiAccountCircleOutline,
    mdiChevronRight,
    mdiFileExcel
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import UserAvatar from '@/components/UserAvatar'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
    name: 'UsersPage',

    mixins: [ControlsMixin],

    directives: {
        infiniteScroll
    },

    components: {
        UserAvatar,
        AppBarNavIcon
    },

    data() {
        return {
            loading: false,
            showSearch: false,
            showProfile: false,
            selectedProfile: null,
            filter: {
                search: ''
            },
            icons: {
                settings: mdiDotsVertical,
                sort: mdiUnfoldMoreHorizontal,
                search: mdiMagnify,
                view: mdiAccountCircleOutline,
                arrow: mdiChevronRight,
                export: mdiFileExcel
            },
            snackbar: {
                show: false,
                message: null,
                color: null
            }
        }
    },

    created() {
        this.clearUsers()
        this.fetchUsers(1)
    },

    computed: {
        ...mapState({
            users: state => state.user.list,
            listMeta: state => state.user.listMeta
        }),

        paginationLength() {
            return (this.listMeta.total / this.listMeta.per_page) | 0
        }
    },

    methods: {
        ...mapActions({
            getUsers: 'user/getUsers',
            exportToExcel: 'user/exportToExcel'
        }),

        ...mapMutations({
            clearUsers: 'user/clearUserList'
        }),

        pageChanged(page) {
            this.fetchUsers(page)
        },

        loadMore() {
            if (this.listMeta.current_page < this.listMeta.last_page) {
                this.fetchUsers(this.listMeta.current_page + 1)
            }
        },

        search: debounce(function() {
            this.clearUsers()
            this.fetchUsers()
        }, 600),

        async fetchUsers(page = 1) {
            if (this.loading) return
            let params = { page }
            if (this.filter.search) {
                params.search = this.filter.search
            }
            this.loading = true
            await this.getUsers(params)
            this.loading = false
        },

        showProfileDialog(profile) {
            this.selectedProfile = profile
            this.showProfile = true
        },

        async exportList() {
            this.loading = true
            await this.exportToExcel()
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        })
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'users_list.xlsx')
                    document.body.appendChild(link)
                    link.click()
                    this.showSnackbar('File successfully downloaded', 'success')
                })
                .catch(() => {
                    this.showSnackbar('Ops! Something went wrong', 'red')
                })
            this.loading = false
        },

        showSnackbar(message, color) {
            this.snackbar.message = message
            this.snackbar.color = color
            this.snackbar.show = true
        }
    }
}
</script>
